import WsProvider from './provider/WSProvider'


var serviceName = "webservis";
var moduleName = "faaliyet";


var faaliyetService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path + 'belge/faaliyet/dosya/',

    faaliyetEkle(teknikPersonel, digerPersonel, makine, malzeme, imalat, faaliyetData) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            methodName: "ekle",
            serviceName: moduleName,
            ['data[projeID]']: faaliyetData.projeID,
            ['data[musteriID]']: faaliyetData.musteriID,
            ['data[faaliyetBaslik]']: faaliyetData.faaliyetBaslik,
            ['data[havaDurumu]']: faaliyetData.havaDurumu,
            ['data[isDurumu]']: faaliyetData.isDurumu,
            ['data[tarih]']: faaliyetData.tarih,
            ['data[gun]']: faaliyetData.gun,
            ['data[hazirlayan]']: faaliyetData.hazirlayan,
            ['data[santiyeSefi]']: faaliyetData.santiyeSefi,
        }
        faaliyetData.dosyalar.forEach((item, i) => {
            param[`dosya[${i}]`] = item.raw
        })
        teknikPersonel.forEach((item, i) => {
            param[`personelData[${i}][teknikPersonel]`] = item.teknikPersonel
            param[`personelData[${i}][sayi]`] = item.sayi
        })
        digerPersonel.forEach((item, i) => {
            param[`digerPersonelData[${i}][personel]`] = item.personel
            param[`digerPersonelData[${i}][sayi]`] = item.sayi
        })
        makine.forEach((item, i) => {
            param[`makineData[${i}][makine]`] = item.makine
            param[`makineData[${i}][saat]`] = item.saat
            param[`makineData[${i}][sayi]`] = item.sayi
        })
        malzeme.forEach((item, i) => {
            param[`malzemeData[${i}][malzeme]`] = item.malzeme
            param[`malzemeData[${i}][miktar]`] = item.miktar
            param[`malzemeData[${i}][bolum]`] = item.bolum
        })
        imalat.forEach((item, i) => {
            param[`imalatData[${i}][imalat]`] = item.imalat
        })
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },
    faaliyetListele(durum, baslangic, limit) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            durum,
            baslangic,
            limit,
            serviceName: moduleName,
            methodName: "faaliyetListesi"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    faaliyetSearch(durum, baslangic, limit, firma, proje, baslik, tarih, gun, hazirlayan, sef) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            durum,
            baslangic,
            limit,
            musteriAdi: firma,
            projeAdi: proje,
            baslik: baslik,
            tarih: tarih,
            gun: gun,
            hazirlayan: hazirlayan,
            santiyeSefi: sef,
            serviceName: moduleName,
            methodName: "ara"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    faaliyetDetay(faaliyetID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            ['data[faaliyetID]']: faaliyetID,
            serviceName: moduleName,
            methodName: "faaliyetDetay"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    faaliyetDuzenle(faaliyetID, teknikPersonel, digerPersonel, makine, malzeme, imalat, faaliyetData) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            methodName: "guncelle",
            serviceName: moduleName,
            ['data[faaliyetID]']: faaliyetID,
            ['data[projeID]']: faaliyetData.projeID,
            ['data[musteriID]']: faaliyetData.musteriID,
            ['data[faaliyetBaslik]']: faaliyetData.faaliyetBaslik,
            ['data[havaDurumu]']: faaliyetData.havaDurumu,
            ['data[isDurumu]']: faaliyetData.isDurumu,
            ['data[tarih]']: faaliyetData.tarih,
            ['data[gun]']: faaliyetData.gun,
            ['data[hazirlayan]']: faaliyetData.hazirlayan,
            ['data[santiyeSefi]']: faaliyetData.santiyeSefi,
        }
        faaliyetData.dosyalar.forEach((item, i) => {
            if (item.isApi === undefined || item.isApi === false) param[`dosya[${i}]`] = item.raw
        })
        teknikPersonel.forEach((item, i) => {
            param[`personelData[${i}][teknikPersonel]`] = item.teknikPersonel
            param[`personelData[${i}][sayi]`] = item.sayi
            if (item.teknikPersonelID) param[`personelData[${i}][teknikPersonelID]`] = item.teknikPersonelID
        })
        digerPersonel.forEach((item, i) => {
            param[`digerPersonelData[${i}][personel]`] = item.personel
            param[`digerPersonelData[${i}][sayi]`] = item.sayi
            if (item.digerPersonelID) param[`digerPersonelData[${i}][digerPersonelID]`] = item.digerPersonelID
        })
        makine.forEach((item, i) => {
            param[`makineData[${i}][makine]`] = item.makine
            param[`makineData[${i}][saat]`] = item.saat
            param[`makineData[${i}][sayi]`] = item.sayi
            if (item.makineID) param[`makineData[${i}][makineID]`] = item.makineID
        })
        malzeme.forEach((item, i) => {
            param[`malzemeData[${i}][malzeme]`] = item.malzeme
            param[`malzemeData[${i}][miktar]`] = item.miktar
            param[`malzemeData[${i}][bolum]`] = item.bolum
            if (item.malzemeID) param[`malzemeData[${i}][malzemeID]`] = item.malzemeID
        })
        imalat.forEach((item, i) => {
            param[`imalatData[${i}][imalat]`] = item.imalat
            if (item.imalatID) param[`imalatData[${i}][imalatID]`] = item.imalatID
        })
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },
    faaliyetDosyaSil(dosyaID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            methodName: "dosyaSil",
            serviceName: moduleName,
            dosyaID,
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },
    faaliyetBilgiSil(tur, id, faaliyetID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            methodName: "bilgiSil",
            serviceName: moduleName,
            faaliyetID,
            tur,
            id,
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },
}

export default faaliyetService